export let DEFAULT_AVATAR = "/assets/img/default.png";

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyB2t8ik-hMD8MkN8dLRnmVjYzW_Kp2MRYI",
    authDomain: "sa-safememunicab.firebaseapp.com",
    databaseURL: "https://sa-safememunicab-default-rtdb.firebaseio.com",
    projectId: "sa-safememunicab",
    storageBucket: "sa-safememunicab.appspot.com",
    messagingSenderId: "1044902507898",
    appId: "1:1044902507898:web:1b455daf3055cb2ec5d2c3",
    measurementId: "G-8CL2NFB95B"
  },
  adminEmail: 'support@sa-safeme.com', // you need to create new account in firebase auth manually,
  defaultCarSettings: {
    prices: {
      default: {
        vehicles: {
          suv: {
            base_fare: "1",
            base_km: "10",
            commission_type: "percentage",
            commission_value: "5",
            icon: "assets/img/suv.svg",
            map_icon: "assets/img/map-suv.png",
            name: "SUV",
            per_km: "1",
            seats: "4",
            tax: "5",
            type: "suv",
          }
        }
      }
    }
  },
  defaultSettings: {
    canDriverSignup: true,
    canRiderSignup: true,
    currency: "R",
    driverInitialRating: "5",
    driverPrivacyURL: "https://store.sa-safeme.com/municab-policy",
    driverSignupBonus: "10",
    driverSupportURL: "https://store.sa-safeme.com/contactus",
    driverTermsURL: "https://store.sa-safeme.com/municab-policy",
    riderPrivacyURL: "https://store.sa-safeme.com/municab-policy",
    riderSupportURL: "https://store.sa-safeme.com/contactus",
    riderTermsURL: "https://store.sa-safeme.com/municab-policy",
    sos: "10111"
  }
};

// export let DEFAULT_AVATAR = "./assets/img/default.png";
